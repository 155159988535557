<template>
  <div v-if="issue" class="bg-purple-3">
    <div
      class="padding-horizontal pt-3 pb-3 d-md-flex justify-content-between align-items-center bg-white"
    >
      <div
        v-text="issue.title"
        class="has-hover more-trig"
        :class="{ active: moreOpen }"
        @click="toggle(false)"
      ></div>
      <div class="d-none d-md-block">
        <a :href="issue.pdf" class="button" target="_blank">Download</a>
      </div>
    </div>
    <div class="spoiler bg-white">
      <div
        class="spoiler-content"
        :class="{ active: moreOpen }"
        :style="{ height: h + 'px' }"
      >
        <div class="sp-body" ref="sp">
          <div class="section pt-4">
            <div
              class="issue-list has-hover pt-1"
              v-for="iss in issues"
              :class="{ active: iss.id === issue.id }"
              @click="toggle(iss)"
            >
              {{ iss.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flip-wrap">
      <div class="padding-horizontal d-md-none">
        <a :href="issue.pdf" class="button" target="_blank">Download</a>
      </div>
      <flipbook
        v-if="isDesktop"
        class="flipbook"
        :start-page="startPage"
        :pages="issue.pages"
        :zooms="null"
        v-slot="flipbook"
        ref="flipbook"
      >
        <div class="mag-controls">
          <div
            @click="flipbook.flipLeft"
            class="mag-control mag-control-left"
            :class="{ 'is-disabled': !flipbook.canFlipLeft }"
          ></div>
          <div
            @click="flipbook.flipRight"
            class="mag-control mag-control-right"
            :class="{ 'is-disabled': !flipbook.canFlipRight }"
          ></div>
        </div>
      </flipbook>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Flipbook from "flipbook-vue";
import throttle from "lodash.throttle";

export default {
  name: "Flip",
  components: { Flipbook },
  data() {
    return {
      issues: [],
      issue: null,
      moreOpen: false,
      h: 0,
      isDesktop: true,
      startPage: 0,
    };
  },
  methods: {
    toggle(iss) {
      if (iss) {
        this.issue = iss;
        this.startPage = 1;
        setTimeout(() => {
          this.startPage = 0;
        }, 100);
      }
      this.h =
        this.$refs.sp && !this.moreOpen
          ? this.$refs.sp.getBoundingClientRect().height
          : 0;
      this.moreOpen = !this.moreOpen;
    },
    resize() {
      this.isDesktop = window.innerWidth >= 768;
    },
  },
  created() {
    axios.get("/endpoints/all_issues").then(({ data }) => {
     
      if (data.length) {
        this.issues = data;
        if (this.$root.selectedId) {
          const selected = data.find((mag) => mag.id === this.$root.selectedId);
          this.issue = selected ? selected : data.pop();
        } else {
          this.issue = data.pop();
        }
      }
    });
  },
  mounted() {
    this.resize();
    window.addEventListener("orientationchange", this.resize);
    window.addEventListener("resize", throttle(this.resize, 500));
  },
  beforeDestroy() {
    window.removeEventListener("orientationchange", this.resize);
    window.removeEventListener("resize", throttle(this.resize, 500));
  },
};
</script>
