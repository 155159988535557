import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../scss/style.scss';

import barba from '@barba/core/dist/barba';
import select from 'dom-select';
import throttle from 'lodash.throttle';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min';
import Swiper, { Navigation, Pagination } from 'swiper';
import { classy as cl } from './helpers/classy';
import { scrollLock, scrollUnLock } from './helpers/scrollLock';
import Spoilers from './helpers/spoilers';
// import videoResize from './helpers/videoResize'
import Throwable from './helpers/throw';
import LazyLoad from 'lazyload';
import ClipboardJS from 'clipboard';
import axios from 'axios';
import serialize from 'form-serialize';
import Cookie from 'js-cookie';
import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import Flip from './Flip.vue';
import { slideUp, slideDown, slideToggle } from './helpers/slide';
import addStripeCard from './addStripeCard';
import BlogFilter from './vue/modules/BlogFilter.vue';
import RegisterForm from './vue/modules/RegisterForm.vue';
import TamarikiForm from './vue/modules/RegisterForm/TamarikiForm.vue';

Swiper.use([Navigation, Pagination]);

function initAddCard() {
  if (document.querySelector('.add-card-form')) {
    new addStripeCard();
  }
}

(function (doc, win) {
  // require('viewport-units-buggyfill').init();
  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  const app = {};

  Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
  };
  NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  };

  function isIe() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }

  const nav = function () {
    const burger = select('.burger');
    const menu = select('.menu');
    const barbaWrapper = select('.barba-wrapper');

    function resetRight() {
      menu.style.right = '';
    }

    function lock() {
      window.openModal = menu;
      scrollLock(menu, (w) => {
        menu.style.right = `${w}px`;
      });
      cl(menu).addCl('for-body');
    }

    function unlock() {
      setTimeout(() => {
        window.openModal = false;
        scrollUnLock(() => {
          resetRight();
        });
      }, 300);
      setTimeout(() => {
        cl(menu).rmCl('for-body');
      }, 500);
    }

    if (burger) {
      burger.addEventListener('click', function () {
        if (menu.classList.contains('open')) {
          unlock();
        } else {
          lock();
        }
        cl(menu).toCl('open');
      });
    }

    this.close = function () {
      cl(menu).rmCl('open');
      unlock();
    };

    this.updateColor = function (container) {
      const pageBG =
        container && container.dataset.pageBg ? container.dataset.pageBg : null;
      const sideBar = select('.side-bar', container);
      const sideBarColor =
        sideBar && sideBar.dataset.sideBg ? sideBar.dataset.sideBg : null;

      if (sideBarColor && menu) {
        menu.style.color = sideBarColor;
      }

      if (pageBG) {
        barbaWrapper.style.backgroundColor = pageBG;
      } else {
        barbaWrapper.style.backgroundColor = '';
      }
    };
    this.updateColor();
  };

  function activeNav() {
    const firstSegment = location.pathname.split('/')[1];
    const lastSegment = location.pathname.split('/').pop();
    select.all('.header a, .nav a').forEach(function (nav) {
      const linkSegments = nav.pathname.split('/');
      const linkFirstSegment =
        linkSegments.length > 1 && linkSegments[1] !== ''
          ? linkSegments[1]
          : false;
      const linkSecondSegment =
        linkSegments.length > 2 && linkSegments[2] !== ''
          ? linkSegments[2]
          : false;

      if (linkSecondSegment && linkSecondSegment === lastSegment)
        cl(nav).addCl('active');
      else if (
        linkFirstSegment &&
        !linkSecondSegment &&
        linkFirstSegment === firstSegment
      )
        cl(nav).addCl('active');
      else cl(nav).rmCl('active');
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600,
      });
    }
  }

  function setSmooth(parent) {
    select.all('.smooth-scroll', parent).forEach((anchor) => {
      anchor.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (anchor.dataset.window) {
          smooth(window.innerHeight, 0, 1000);
        } else {
          const target = select(anchor.hash);
          if (target) smooth(target, 40, 1000);
        }
      });
    });

    select.all('.scroll-to-next', parent).forEach((anchor) => {
      anchor.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (anchor.nextElementSibling) {
          smooth(anchor.nextElementSibling, 40, 1000);
        }
      });
    });
  }

  function pushPage(title, url) {
    history.pushState({ urlPath: url }, title, url);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: url,
        title: title,
      },
    });
  }

  app.swipers = [];
  function swipers(parent) {
    app.swipers.forEach((sw) => sw.destroy());

    select.all('.swiper-regular', parent).forEach((sw) => {
      const caption = select('.caption-goes-here', sw);
      app.swipers.push(
        new Swiper(sw, {
          speed: 1000,
          loop: true,
          pagination: {
            type: 'bullets',
            clickable: true,
            el: select('.swiper-pagination', sw),
          },
          navigation: {
            nextEl: select('.swiper-nav.control-right', sw),
            prevEl: select('.swiper-nav.control-left', sw),
          },
          on: {
            init() {
              const slide = this.slides[this.activeIndex];
              const text = slide.dataset.caption ? slide.dataset.caption : null;
              if (text && caption) {
                cl(caption).rmCl('caption-goes-here');
                caption.innerHTML = text;
              }
            },
            slideChange() {
              const slide = this.slides[this.activeIndex];
              const text = slide.dataset.caption ? slide.dataset.caption : null;
              if (text && caption) {
                cl(caption).rmCl('caption-goes-here');
                caption.innerHTML = text;
              }
            },
          },
        })
      );
    });

    const swiperForm = select('.swiper-form', parent);
    if (swiperForm) {
      app.swipers.push(
        new Swiper(swiperForm, {
          speed: 1000,
          allowTouchMove: false,
          spaceBetween: 40,
          pagination: {
            type: 'bullets',
            el: select('.swiper-pagination', swiperForm),
          },
          on: {
            init: function () {
              const form1 = document.querySelector('.form-step1');
              const form2 = document.querySelector('.form-step2');
              const mailchimp = document.getElementById('mailchimp');
              let whanauId = swiperForm.dataset.whanauId
                ? swiperForm.dataset.whanauId
                : null;
              let last = swiperForm.dataset.last
                ? swiperForm.dataset.last
                : null;

              if (whanauId) {
                this.slideTo(1, 0);
              } else if (last) {
                this.slideTo(this.slides.length - 1, 0);
              }

              if (form1) {
                form1.addEventListener('submit', (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  form1
                    .querySelector("[type='submit']")
                    .classList.add('loading');
                  axios
                    .post('/', serialize(form1))
                    .then(({ data }) => {
                      if (data.whanauId) {
                        whanauId = data.whanauId;
                        this.slideNext();
                        pushPage(
                          '',
                          window.location.pathname + '?whanau=' + data.whanauId
                        );
                      } else if (!data.success) {
                        console.log(data);
                      }
                      if (window.innerWidth < 1024) {
                        smooth(swiperForm, 50);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                });
              }

              if (mailchimp) {
                mailchimp.addEventListener('submit', (e) => {
                  e.preventDefault();
                  mailchimp
                    .querySelector("[type='submit']")
                    .classList.add('loading');
                  fetch('/mailchimp/send', {
                    method: 'post',
                    body: new FormData(mailchimp),
                  })
                    .then((r) => r.json())
                    .then((r) => {
                      if (
                        r.success ||
                        r.msg === 'Mailchimp error: Member Exists'
                      ) {
                        this.slideNext();
                        pushPage(
                          'Thank you',
                          window.location.pathname + '/thanks'
                        );
                      } else {
                        console.error(r.msg);
                      }
                    })
                    .catch((e) => {
                      console.error(e);
                    });
                });
              }

              if (form2) {
                form2.addEventListener('submit', (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (whanauId) {
                    axios
                      .post(
                        window.location.href,
                        serialize(form2) + '&whanauId=' + whanauId
                      )
                      .then(({ data }) => {
                        if (data.success) {
                          this.slideNext();
                          pushPage(
                            'Thank you',
                            window.location.pathname + '/thanks'
                          );
                        }
                        if (window.innerWidth < 1024) {
                          smooth(swiperForm, 50);
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                });
              }
            },
          },
        })
      );
    }

    setTimeout(function () {
      app.swipers.forEach((sw) => sw.update());
    }, 200);
  }

  function addaKid(parent) {
    const wrap = select('.add-kid-wrap', parent);
    const add = select('.add-kid', wrap);
    const addHere = select('.add-here', wrap);
    let loop = 2;

    if (add && wrap && addHere) {
      add.addEventListener('click', () => {
        const newKid = document.createElement('div');
        newKid.innerHTML += `
          <div class="more-kids">
            <div class="d-flex justify-content-between">
              <p class="pt-2"><b>Kid #${loop}</b></p>
              <div><span class="t-sm link remove-kid">Remove</span></div>
            </div>
            <div class="input-wrap">
                <input class="input" type="text" name="kids[kid${loop}][name]" id="name" required>
                <label class="input-label" for="name">First name *</label>
            </div>
            <div class="input-wrap">
                <input class="input" type="text" name="kids[kid${loop}][lname]" id="lname">
                <label class="input-label" for="lname">Last name (if different to yours)</label>
            </div>
            <div class="input-wrap">
                <input class="input" type="text" name="kids[kid${loop}][address]" id="address" required>
                <label class="input-label" for="address">Postal address *</label>
            </div>
            <div class="input-line">
                <div class="input-wrap">
                    <input class="input" type="text" name="kids[kid${loop}][city]" id="city" required>
                    <label class="input-label" for="city">City *</label>
                </div>
                <div class="input-wrap">
                    <input class="input" type="text" name="kids[kid${loop}][postcode]" id="postcode" required>
                    <label class="input-label" for="postcode">Post code *</label>
                </div>
            </div>
            <div class="input-wrap">
                <input class="input" type="text" name="kids[kid${loop}][country]" id="country" required>
                <label class="input-label" for="country">Country *</label>
            </div>
            <div class="input-line">
                <div class="input-wrap">
                    <input class="input" type="text" name="kids[kid${loop}][year]" id="year" required>
                    <label class="input-label" for="year">Year of birth *</label>
                </div>
                <div class="input-wrap"></div>
            </div>
          </div>
        `;
        addHere.appendChild(newKid);
        loop++;
        inputs(newKid);

        const remove = select('.remove-kid', newKid);
        if (remove) {
          remove.addEventListener('click', () => {
            loop--;
            newKid.remove();
          });
        }
      });
    }
  }

  function accountForms(parent) {
    document.querySelectorAll('.updateuser').forEach((form) => {
      const button = select('.button', form);

      let posting = false;

      function reset() {
        posting = false;
        cl(button).rmCl('is-loading');
        form.classList.add('is-posted');
        setTimeout(() => {
          form.classList.remove('is-posted');
        }, 3000);
      }

      form.addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        cl(button).addCl('is-loading');

        if (!posting) {
          posting = true;
          axios
            .post(window.location.href, serialize(form))
            .then(({ data }) => {
              reset();
              if (!data.success) {
                console.log(data);
              }
            })
            .catch((e) => {
              reset();
              console.log(e);
            });
        }
      });
    });
  }

  function addaKidForm(parent) {
    const wrap = select('.add-kidform-wrap', parent);
    const add = select('.add-kidform', wrap);
    const addHere = select('.add-here', wrap);
    let loop = 2;

    if (add && wrap && addHere) {
      add.addEventListener('click', () => {
        const newKid = document.createElement('div');
        newKid.innerHTML += `
          <div class="more-kids">
            <div class="d-flex justify-content-between">
              <p class="pt-2"><b>Kid #${loop}</b></p>
              <div><span class="t-sm link remove-kid">Remove</span></div>
            </div>
            <input type="hidden" name="fields[userChildren][new${loop}][type]" value="child">
            <input type="hidden" name="fields[userChildren][new${loop}][enabled]" value="1">
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][firstName]">
            <label class="input-label" for="firstName">First name *</label>
          </div>
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][lastName]">
             <label class="input-label" for="lastName">Last name (if different to yours)</label>
          </div>
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][year]">
            <label class="input-label" for="year">Year of birth</label>
          </div>
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][address]">
            <label class="input-label" for="address">Postal address *</label>
          </div>
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][city]">
            <label class="input-label" for="address">City *</label>
          </div>
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][postcode]">
             <label class="input-label" for="postcode">Post code *</label>
          </div>
            
            <div class="input-wrap">
            <input type="text"  class="input" name="fields[userChildren][new${loop}][fields][country]">
            <label class="input-label" for="country">Country *</label>
          </div>
            
          </div>
        `;
        addHere.appendChild(newKid);
        loop++;
        inputs(newKid);

        const remove = select('.remove-kid', newKid);
        if (remove) {
          remove.addEventListener('click', () => {
            loop--;
            newKid.remove();
          });
        }
      });
    }
  }

  app.lazyload = null;
  function setLazy(parent) {
    if (app.lazyload) app.lazyload.destroy();
    const images = select.all('.lazyload', parent);
    app.lazyload = new LazyLoad(images);
  }

  app.clipboards = null;
  function setClipboards(parent) {
    if (app.clipboards) app.clipboards.destroy();
    const clipboards = parent.querySelectorAll('.clipboard');
    app.clipboards = new ClipboardJS(clipboards);
    app.clipboards.on('success', function (e) {
      e.trigger.innerHTML = 'Copied to clipboard';
      setTimeout(() => {
        e.trigger.innerHTML = 'Share';
      }, 4000);
    });
  }

  app.flip = null;
  function flipApp(parent) {
    const cont = select('#app', parent);
    if (cont && typeof Vue !== 'undefined') {
      const selectedId = Number(cont.dataset.selected);
      app.flip = new Vue({
        el: cont,
        data: { selectedId },
        render: (h) => h(Flip),
      });
    }
  }

  function initVue() {
    const initialisedVueComponents = [];

    Vue.use(VueFormulate, {
      classes: {
        input: (context, classes) => {
          return classes.concat([
            context.type !== 'radio' && context.type !== 'checkbox'
              ? 'input'
              : `input-${context.type}`,
          ]);
        },
        label: (context, classes) => {
          return classes.concat(['input-label']);
        },
        wrapper: (context, classes) => {
          return classes.concat([`formulate-${context.type}`]);
        },
        outer: (context, classes) =>
          classes.concat([
            context.type !== 'radio' && context.type !== 'checkbox'
              ? 'input-wrap'
              : `form-group--${context.type}`,
          ]),
      },
    });

    if (document.getElementById('vue-register-form')) {
      Vue.config.devtools = true;
      const vueRegister = new Vue({
        el: '#vue-register-form',
        components: {
          RegisterForm,
        },
      });
      initialisedVueComponents.push(vueRegister);
    }

    if (document.getElementById('vue-blog-filter')) {
      Vue.config.devtools = true;
      const vueBlogFilter = new Vue({
        el: '#vue-blog-filter',
        components: {
          BlogFilter,
        },
      });
      initialisedVueComponents.push(vueBlogFilter);
    }
    if (document.getElementById('vue-tamariki-form')) {
      const vueTamariki = new Vue({
        el: '#vue-tamariki-form',
        components: {
          TamarikiForm,
        },
      });
      initialisedVueComponents.push(vueTamariki);
    }

    barba.hooks.leave(() => {
      initialisedVueComponents.forEach((component, index) => {
        component.destroy();
        initialisedVueComponents.shift();
      });
    });
  }

  function cookieBar() {
    const coockieVal = Cookie.get('cookie-bar');
    const bar = select('.cookie-bar');
    const button = select('.button', bar);

    if (!coockieVal) {
      setTimeout(() => {
        cl(bar).addCl('open');
      }, 5000);
    }

    if (button) {
      button.addEventListener('click', () => {
        cl(bar).rmCl('open');
        Cookie.set('cookie-bar', 'closed', { expires: 30 });
      });
    }
  }

  function inputs(parent) {
    select.all('.input', parent).forEach((input) => {
      if (input.value !== '') cl(input).addCl('filled');
      input.addEventListener('focus', function (el) {
        cl(el.target).addCl('filled');
      });
      input.addEventListener('focusout', function (el) {
        if (input.value === '') cl(el.target).rmCl('filled');
      });
      input.addEventListener('change', function (el) {
        cl(el.target).addCl('filled');
      });
    });
  }

  function forms(parent) {
    const search = select('.search-form', parent);
    const searchTo = select('.search-toggle', parent);
    const searchInput = select('#search', search);

    if (search) {
      search.addEventListener('submit', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.target.action) {
          const query = serialize(ev.target);
          barba.go(ev.target.action + '?' + query);
        }
      });
    }

    if (searchTo) {
      searchTo.addEventListener('click', () => {
        if (!searchTo.classList.contains('open') && searchInput) {
          setTimeout(() => {
            searchInput.focus();
          }, 10);
        }
        cl(searchTo).toCl('open');
      });
    }

    document.querySelectorAll('.newsletter').forEach((form) => {
      const button = form.querySelector('.button');

      let posting = false;

      function reset() {
        posting = false;
        cl(button).rmCl('is-loading');
        form.classList.add('is-posted');
        setTimeout(() => {
          form.reset();
          form.forEach((f) => cl(f).rmCl('filled'));
          form.classList.remove('is-posted');
        }, 3000);
      }

      form.addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('test');
        button.classList.add('loading');
        if (!posting) {
          posting = true;
          axios
            .post(window.location.href, serialize(form))
            .then(({ data }) => {
              reset();
              if (!data.success) {
                console.log(data);
              }
            })
            .catch((e) => {
              reset();
              console.log(e);
            });
        }
      });
    });
  }

  function loadMore(parent) {
    select.all('.is-paginated', parent).forEach((pagination) => {
      const container = select('.pagination-container', pagination);
      const button = select('.load-more', pagination);
      const blockId =
        pagination && pagination.dataset.blockId
          ? pagination.dataset.blockId
          : null;
      let page = 'p2';

      if (button) {
        button.addEventListener('click', () => {
          if (page) {
            cl(button).addCl('is-loading');
            axios
              .get(
                `/endpoints/article_p/${page}?${
                  blockId ? 'block_id=' + blockId : ''
                }`,
                { responseType: 'document' }
              )
              .then(({ data }) => {
                cl(button).rmCl('is-loading');
                if (data) {
                  const articles = select('.pag-entries', data);
                  page =
                    articles &&
                    articles.dataset.nextPage &&
                    articles.dataset.nextPage !== 'null'
                      ? articles.dataset.nextPage
                      : null;
                  if (!page) {
                    cl(button).addCl('d-none');
                  }
                  if (articles) {
                    container.innerHTML += articles.innerHTML;
                  }
                }
              });
          }
        });
      }
    });
  }

  const iosAppHeight = () => {
    function iosHeightFix() {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
    iosHeightFix();
    window.addEventListener('resize', throttle(iosHeightFix, 200));
  };
  iosAppHeight();

  function outBarba() {
    app.nav = new nav();
    cookieBar();
  }

  function headerLogin() {
    const headerLogin = document.querySelector('#header-login');
    if (headerLogin) {
      const trigger = headerLogin.querySelector('#header-login__trigger');
      const loginForm = headerLogin.querySelector('#header-login__form');

      trigger.addEventListener('click', (event) => {
        if (window.innerWidth > 1280 && window.user === 'null') {
          event.preventDefault();
          event.stopPropagation();

          slideToggle(loginForm);
        }
      });
    }
  }

  app.videRes = null;
  function onBarba(parent) {
    cl('.barba-container').rmCl('hide');
    activeNav();
    if (parent) {
      swipers(parent);
      setSmooth(parent);
      setLazy(parent);
      setClipboards(parent);
      inputs(parent);
      addaKidForm(parent);
      addaKid(parent);
      forms(parent);
      accountForms(parent);
      flipApp(parent);
      loadMore(parent);
      initVue();
      initAddCard();
      headerLogin();
      app.spoilers = new Spoilers(parent);
      //app.videRes = new videoResize('.has-cover-video')

      if (app.throw) {
        app.throw.destroy();
        setTimeout(() => {
          app.throw = new Throwable(parent);
        }, 500);
      } else if (!isIe()) {
        app.throw = new Throwable(parent);
      }
    }
  }

  doc.addEventListener('DOMContentLoaded', function () {
    outBarba();

    // win.addEventListener('scroll', throttle(function () {
    // }, 50));

    win.addEventListener(
      'resize',
      throttle(function () {
        if (app.nav) app.nav.close();
        if (app.throw) app.throw.resize();
        // if (app.videRes) app.videRes.resize();
      }, 300)
    );

    if (!isIe()) {
      barba.init({
        timeout: 5000,
        //prefetchIgnore: true,

        transitions: [
          {
            name: 'slide',
            sync: true,
            from: {
              custom({ trigger }) {
                return (
                  trigger.classList &&
                  trigger.classList.contains('side-bar-text')
                );
              },
            },
            leave() {
              const done = this.async();
              setTimeout(() => {
                cl('.barba-wrapper').rmCl('slide-anim');
                cl('html').rmCl('slide-anim-all');
                done();
              }, 500);
            },
            enter({ next, current }) {
              const prevWidth = current.container.getBoundingClientRect().width;
              current.container.style.width = prevWidth + 'px';
              next.container.style.width = prevWidth + 'px';

              cl('.barba-wrapper').addCl('slide-anim');
              cl('html').addCl('slide-anim-all');
              cl(next.container).rmCl('hide');
              setTimeout(() => {
                next.container.style.width = null;
              }, 550);
            },
          },
          {
            sync: true,
            name: 'transition',
            leave() {
              const done = this.async();
              setTimeout(done, 500);
            },
            enter({ next, current }) {
              cl(current.container).addCl('hide');
            },
          },
        ],
      });

      barba.hooks.beforeEnter(() => {
        window.scrollTo(0, 0);
      });

      barba.hooks.enter(({ next }) => {
        if (app.nav) {
          app.nav.updateColor(next.container);
          app.nav.close();
        }
      });

      barba.hooks.afterEnter(({ next }) => {
        onBarba(next.container);

        select.all('video', next.container).forEach((v) => {
          v.play();
        });
      });

      barba.hooks.after(() => {
        // for GTAG or GA
        window.dataLayer.push({
          event: 'pageview',
          page: {
            path: window.location.pathname,
            title: document.title,
          },
        });
      });
    } else {
      onBarba(document);
    }
  });
})(document, window);

window.onload = () => {
  initAddCard();
};
