import Vue from 'vue';
import axios from 'axios';
import { marked } from 'marked';
import ImgixClient from '@imgix/js-core';

const state = Vue.observable({
	// cart
	cart: undefined,
	currentUser: undefined,
	csrf: undefined,
	// other
	loading: true,
	imgix: new ImgixClient({
		domain: 'kiwi-kids-abroad.imgix.net',
	}),
	gScore: 0,

});

const getters = {
	cart: () => state.cart,
	csrf: () => state.csrf,
	currentUser: () => state.currentUser,
	// other
	loading: () => state.loading,
	imgix: () => state.imgix,
};

const mutations = {
	setCart: (v) => { state.cart = v; },
	setCsrf: (v) => { state.csrf = v; },
	setCurrentUser: (v) => { state.currentUser = v; },
	// other
	setLoading: (v) => { state.loading = v; },
};

const actions = {
	async csrfFetch() {
		return await axios.get('/endpoints/csrf').then((response) => {
			if (response.data.token) {
				mutations.setCsrf(response.data);

				return true;
			}
			return false;
		}).catch(() => false);
	},
	async currentUserFetch() {
		await axios.get('/endpoints/currentUser').then((response) => {
			mutations.setCurrentUser(response.data);
			return true;
		}).catch(() => false);
	},
	handelize(str) {
		return str.replace('& ', '').replace(/\s/g, '-').toLowerCase();
	},
	imgixImg(img, options = { auto: 'compress' }) {
		const newImg = img;
		if (img.path) {
			newImg.url = state.imgix.buildURL(newImg.path, options);
		} else {
			const newUrl = img.url.replace(window.location.hostname, '').replace(window.location.protocol, '').replace('//images', 'images');
			newImg.url = state.imgix.buildURL(newUrl, options);
		}

		newImg.url = state.imgix.buildURL(img.path, options);
		newImg.small = state.imgix.buildURL(img.path, { px: 100 });

		return newImg;
	},
	marked(value, options) {
		if (options && options.inline) {
			return marked.parseInline(value);
		}
		return marked.parse(value);
	},
	async cartFetch() {
		mutations.setLoading(true);
		if (getters.csrf === undefined) {
			await actions.csrfFetch().then(() => {
				return this.cartQuery()
			});
		} else {
			return await this.cartQuery()
		}
		console.log(cart)
		return cart;
	},
	async cartQuery() {
		const data = new FormData();
		data.append(getters.csrf().name, getters.csrf().token);
		data.append("action", "commerce/cart/get-cart");
		//
		return await axios
			.post("/", data, {
				headers: {
					Accept: "application/json"
				}
			})
			.then(response => {
				mutations.setLoading(false);
				mutations.setCart(response.data.cart);
				return true
			})
			.catch(error => {
				// this.error = error;
				console.error(error);
			});
	}
};

export {
	getters, mutations, actions, state,
};
