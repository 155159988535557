<template>
  <div class="step-1 h-full">
    <div
      class="wysiwyg border-bottom border-dark mb-4 pb-5"
      v-html="marked(content.text)"
    />
    <p class="mb-5">
      <b>Add your little Kiwi(s) (to recieve Tamariki Times) </b>
    </p>

    <TamarikiForm
      @success="handleStep(2)"
      :user-children="userChildren"
      :sign-up="true"
    />
  </div>
</template>

<script>
import { actions, getters } from "../../../vue/store.js";
import TamarikiForm from "./TamarikiForm.vue";

export default {
  name: "StepOne",
  components: {
    TamarikiForm,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    userChildren: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      success: false,
      loading: false,
    };
  },
  computed: {
    csrf() {
      return getters.csrf();
    },
    currentUser() {
      return getters.currentUser();
    },
  },
  methods: {
    marked(value, options) {
      if (options) {
        return actions.marked(value, options);
      }
      return actions.marked(value);
    },
    handleStep(step) {
      this.$emit("step", step);
    },
  },
};
</script>
