<template>
  <div id="resource-filter" class="overflow-hidden pt-5">
    <div class="row pagination-container pb-5">
      <div class="col-md-12">
        <transition name="slideDown">
          <content-filter
            :tags="tags"
            :ages="ages"
            placeholder="1"
            @updated="handleUpdate"
          />
        </transition>
      </div>
    </div>

    <div
      class="row pagination-container"
      :class="{ 'pagination-container--loading': loading }"
    >
      <div
        v-for="(item, i) in filteredArticles"
        :key="`blog-${handelize(item.name)}-${i}`"
        class="col-md-6 transition-opacity duration-300 fade-in"
      >
        <a :href="item.url" class="resource-tile">
          <h3 class="title is-3 text-center">{{ item.name }}</h3>
          <div class="itemsEntries resource-image pb-4">
            <img
              class="md:h-full w-full md:absolute md:top-0 md:left-0 md:object-cover"
              v-if="item.image[0]"
              :src="image(item.image[0]).url"
              :alt="image(item.image[0]).alt"
              :data-src="image(item.image[0]).url"
            />
            <div v-if="item.video" class="play-button"></div>
          </div>
          <div>{{ item.text }}</div>
          <span class="link pt-4 no-bg">Read more</span>
        </a>
      </div>
    </div>

    <div
      v-if="!filteredArticles.length && !loading"
      class="w-full title is-3 capitalize"
    >
      No results in {{ filters.tag }}
    </div>

    <div
      class="text-center pt-5"
      v-if="filteredArticles.length > 3"
      @click="loadMore"
    >
      <span
        class="button has-loader text-black load-more"
        :class="{ loading: loading }"
        >Load more</span
      >
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import ContentFilter from '../../vue/components/ContentFilter.vue';
import ImgixClient from '@imgix/js-core';

export default {
  name: 'BlogFilter',
  components: {
    ContentFilter,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    ages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: Boolean,
      loading: true,
      imgix: new ImgixClient({
        domain: 'kiwi-kids-abroad.imgix.net',
        secureURLToken: '6334ddf5e0d198597ec7dbd7',
      }),
      filters: {
        tag: '',
      },
      resultsPerPage: 6,
      currentPage: 1,
      articles: [],
      shownArticles: [],
    };
  },
  computed: {
    filteredArticles() {
      // if (this.articles.length && this.filters.tag !== '') {
      //   return this.articles.filter((item) => {
      //     return (
      //       item.category.includes(this.filters.tag) ||
      //       item.age.includes(this.filters.tag)
      //     );
      //   });
      // }
      return this.articles;
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.loading = true;
        this.shownArticles = [];

        const valCheck = this.handelize(val.tag);

        if (valCheck === '') {
          this.shownArticles = this.blogs;
          setTimeout(() => {
            this.loading = false;
          }, 200);
          return;
        }
      },
      deep: true,
    },
  },
  created() {
    this.getArticles(this.articleQuery());
  },
  mounted() {
    this.filters.tag = this.tags[0].handle;
  },

  methods: {
    handelize(str) {
      return str.replace('& ', '-').replace(/\s/g, '-').toLowerCase();
    },
    handleUpdate(value) {
      this.currentPage = 1;
      this.filters.tag = value;
      this.getArticles(this.articleQuery());
    },
    loadMore() {
      this.currentPage += 1;
      this.getArticles(this.articleQuery());
    },
    imgixImg(img, options = { auto: 'compress' }) {
      const newImg = img;
      if (img.path) {
        newImg.url = this.imgix.buildURL(newImg.path, options);
      } else {
        const newUrl = img.url
          .replace(window.location.hostname, '')
          .replace(window.location.protocol, '')
          .replace('//images', 'images');
        newImg.url = this.imgix.buildURL(newUrl, options);
      }
      newImg.url = this.imgix.buildURL(img.path, options);
      newImg.small = this.imgix.buildURL(img.path, { px: 100 });
      return newImg;
    },

    image(img) {
      let image;
      if (img) {
        image = img;
      } else {
        [image] = img;
      }
      if (window.location.hostname.includes('test')) {
        return image;
      }
      return this.imgixImg(image);
    },

    articleQuery(tag) {
      const params = [];
      params.push(`limit : ${this.resultsPerPage}`);
      params.push(`offset: ${(this.currentPage - 1) * this.resultsPerPage}`);
      if (this.filters.tag != '') {
        params.push(`relatedToCategories: {slug: "${this.filters.tag}"}`);
      }
      return `query articles{
				entries(section: "articles", ${params.join(', ')}  ) {
					... on articles_articles_Entry {
						name: title
						url
						text: short
						video
						image: listImage {
							... on images_Asset {
								src: url,
								alt: title,
								url,
								path
							}
						}
						contentCategory{
							title
						}
						age{
							title
						}
					}
				}
			}
			`;
    },
    getArticles(qObject) {
      this.loading = true;
      axios('/api', {
        method: 'post',
        data: {
          query: qObject,
        },
      })
        .then((response) => response.data)
        .then((response) => {
          console.log(response);
          if (this.currentPage > 1) {
            this.articles = this.articles.concat(response.data.entries);
          } else {
            this.articles = response.data.entries;
          }

          this.shownArticles = this.articles;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
