import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from './InertiaPlugin'
import select from 'dom-select'
import { classy as cl } from './classy'
import debounce from 'lodash.debounce'

const Throwable = function (parent) {
  const friction = -0.5
  const containers = []

  gsap.defaults({ overwrite: true });

  if (typeof window !== "undefined") {
    gsap.registerPlugin(InertiaPlugin);
  }

  select.all('.has-pics .throw-pics', parent).forEach((container, contIndex) => {
    const throws = select.all('.throwable', container).map(t => setThrowable(t, container, contIndex))

    let vw = container.getBoundingClientRect().width;
    let vh = container.getBoundingClientRect().height;
    containers.push({
      container,
      vw,
      vh,
      throws
    })
  })


  function setThrowable(throwable, container, contIndex) {
    const radius = throwable.getBoundingClientRect().width / 2;
    const tracker = InertiaPlugin.track(throwable, "x,y")[0];

    function animateBounce(x = "+=0", y = "+=0", vx = "auto", vy = "auto") {
      gsap.killTweensOf(throwable);
      gsap.fromTo(throwable, { x, y }, {
        inertia: {
          x: vx,
          y: vy,
        },
        onUpdate: debouncedBounce
      });
    }

    const debouncedBounce = debounce(checkBounds, 10)

    function checkBounds() {
      let r = radius;
      let x = gsap.getProperty(throwable, 'x');
      let y = gsap.getProperty(throwable, 'y');
      let vx = tracker.get("x");
      let vy = tracker.get("y");
      let xPos = x;
      let yPos = y;

      const vw = containers[contIndex].vw
      const vh = containers[contIndex].vh

      let hitting = false

      if (x + r > vw) {
        xPos = vw - r;
        vx *= friction;
        hitting = true;

      } else if (x - r < 0) {
        xPos = r;
        vx *= friction;
        hitting = true;
      }

      if (y + r > vh) {
        yPos = vh - r;
        vy *= friction;
        hitting = true;

      } else if (y - r < 0) {
        yPos = r;
        vy *= friction;
        hitting = true;
      }

      if (hitting) {
        animateBounce(xPos, yPos, vx, vy);
      }
    }

    return new Draggable(throwable, {
      bounds: container,
      onPress(ev) {
        if (!throwable.classList.contains('is-moving')) {
          const h = throwable.getBoundingClientRect()
          gsap.set(throwable, {
            xPercent: -50,
            yPercent: -50,
            x: ev.clientX,
            y: ev.clientY
          });
        }
        gsap.killTweensOf(throwable);
        cl(throwable).addCl('is-moving')
        this.update();
      },
      onDragEnd: animateBounce,
      onDragEndParams: []
    });
  }

  this.resize = function () {
    containers.forEach(c => {
      c.vw = c.container.getBoundingClientRect().width;
      c.vh = c.container.getBoundingClientRect().height;
    })
  }

  this.destroy = function () {
    containers.forEach(c => {
      c.throws.forEach(t => {
        gsap.killTweensOf(t.target);
        //t.kill()
      })
    })
  }
}

export default Throwable

