<template>
  <div class="step-2 h-full">
    <p><b>Payment</b></p>

    <div class="mt-5 mb-4">
      <!-- <div class="form-group form-group--radio">
        <input
          v-model="orderType"
          name="order-type"
          type="radio"
          value="order"
          id="order-radio"
        />
        <label for="order-radio" class="cursor-pointer">
          2 issues - ${{ this.products.variant.price }}
        </label>
      </div> -->
      <div class="form-group form-group--radio">
        <input
          v-model="orderType"
          type="radio"
          name="order-type"
          value="subscription"
          id="subscription-radio"
        />
        <label for="subscription-radio" class="cursor-pointer">
          Reccuring Subscription - {{ this.price }}
          {{ plan.plan.interval === 'year' ? 'Annually' : plan.plan.interval }}
        </label>
      </div>
    </div>
    <div class="row row--normal">
      <div class="col-lg-12 mt-5 stripe-card">
        <div class="row row--normal">
          <div class="col-12 col-sm-6">
            <div class="input-wrap">
              <input
                placeholder="First Name"
                type="text"
                v-model="cardFirstName"
                class="input"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="input-wrap">
              <input
                placeholder="Last Name"
                type="text"
                v-model="cardLastName"
                class="input"
              />
            </div>
          </div>
        </div>
        <div class="input-wrap">
          <div id="number-element" class="stripe-input">
            <!-- {# Stripe's JavaScript will insert Stripe Elements here #} -->
          </div>
        </div>
        <div class="row row--normal">
          <div class="col-12 col-sm-6">
            <div class="input-wrap">
              <div id="expiry-element" class="stripe-input">
                <!-- {# Stripe's JavaScript will insert Stripe Elements here #} -->
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="input-wrap">
              <div id="cvc-element" class="stripe-input">
                <!-- {# Stripe's JavaScript will insert Stripe Elements here #} -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="errors" class="col-12 mt-5">
        <ul>
          <li v-if="typeof errors === 'string'">
            <span>{{ errors }}</span>
          </li>
          <template v-else>
            <li v-for="(value, key) in errors">
              <span class="is-capitalized">{{ key }} :</span>
              <template v-if="typeof value === Array">
                <span v-for="problem in value">{{ problem }} <br /></span>
              </template>
              <template v-else>
                <span>{{ value }}</span>
              </template>
            </li>
          </template>
        </ul>
      </div>
      <div class="col-12 mt-5">
        <div
          class="button cursor-pointer"
          :class="[{ loading: loading }, { 'opacity-50': success }]"
          :disabled="loading || success || disabled"
          @click="handleSubmit"
        >
          {{ orderType === 'order' ? 'Buy' : 'Subscribe' }} now
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  import { StripeElementCard } from '@vue-stripe/vue-stripe';
  import { actions, getters } from '../../../vue/store.js';

  export default {
    name: 'StepTwo',
    components: {
      StripeElementCard,
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
      products: {
        type: Object,
        required: true,
      },
      plan: {
        type: Object,
        required: true,
      },
      planId: {
        type: String,
        required: true,
      },
      stripePk: {
        type: String,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      customer: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        orderType: 'subscription',
        success: false,
        loading: false,
        disabled: true,
        errors: '',
        message: '',
        cardFirstName: '',
        cardLastName: '',
      };
    },

    mounted() {
      actions.currentUserFetch();
      actions.cartFetch().then(() => {
        this.emptyCartAddItem();
      });
      this.initStripe();
    },

    computed: {
      currentUser() {
        return getters.currentUser();
      },
      cart() {
        return getters.cart();
      },
      csrf() {
        return getters.csrf();
      },
      stripe() {
        return window.Stripe(this.stripePk);
      },
      price() {
        return new Intl.NumberFormat('en-NZ', {
          style: 'currency',
          currency: 'NZD',
        }).format(this.plan.plan.amount / 100);
      },
    },

    methods: {
      emptyCartAddItem() {
        if (this.cart.lineItems.length) {
          this.clearCart().then(() => {
            this.addItemUpdateCart();
          });
        } else {
          this.addItemUpdateCart();
        }
      },
      addItemUpdateCart() {
        this.addProduct().then(() => {
          actions.cartFetch();
          console.log(this.cart.lineItems);
        });
      },
      initStripe() {
        const elements = this.stripe.elements();
        const style = {
          base: {
            'color': '#000',
            'backgroundColor': '#fff',
            'fontSmoothing': 'antialiased',
            'fontSize': '20px',
            '::placeholder': {
              color: '#757575',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        };
        this.cardNumber = elements.create('cardNumber', {
          style,
          placeholder: 'Card Number',
        });
        this.cardNumber.mount('#number-element');

        this.cardExpiry = elements.create('cardExpiry', { style });
        this.cardExpiry.mount('#expiry-element');

        this.cardCvc = elements.create('cardCvc', { style });
        this.cardCvc.mount('#cvc-element');
      },
      handleStep(step) {
        this.$emit('step', step);
      },
      async clearCart() {
        let data = new FormData();
        data.append(this.csrf.name, this.csrf.token);
        data.append('action', '/commerce/cart/update-cart');
        data.append(`lineItems[${this.cart.lineItems[0].id}][remove]`, 1);
        return await axios
          .post('/', data, {
            headers: { Accept: 'application/json' },
          })
          .then((response) => {
            if (response.data.success) {
              console.log(response);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      },
      async addProduct() {
        const data = new FormData();
        this.disabled = true;

        data.append('action', 'commerce/cart/update-cart');
        data.append('purchasableId', this.products.variant.id);
        data.append('qty', 1);
        data.append(this.csrf.name, this.csrf.token);

        return await axios
          .post('/', data, {
            headers: { Accept: 'application/json' },
          })
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              console.log('success');
            } else if (!response.data.success) {
              const { message } = response.data;

              console.log(message);
            }
            this.disabled = false;
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      },

      async processPayment(token) {
        let formData = new FormData();
        formData.append(this.csrf.name, this.csrf.token);

        formData.append('paymentMethodId', token);
        if (this.orderType === 'order') {
          formData.append('action', 'commerce/payments/pay');
          formData.append('gatewayId', 2);
        } else {
          console.log('subscribe');
          formData.append('savePaymentSource', '1');
          formData.append('action', 'commerce/subscriptions/subscribe');
          formData.append('firstName', this.currentUser.firstName);
          formData.append('lastName', this.currentUser.lastName);
          formData.append('username', this.currentUser.username);
          formData.append('email', this.currentUser.email);
          formData.append('planUid', this.planId);
          // formData.append("payment_settings[payment_method_types][]", "card");
        }

        axios
          .post('/', formData, {
            headers: {
              Accept: 'application/json',
            },
          })
          .then((response) => {
            console.log(response.data);
            return response.data;
          })
          .then((data) => {
            if (data.error) {
              this.errors = data.error;
            }
            if (data.success || data.message == 'Subscription started.') {
              this.errors = '';
              this.handleStep(3);
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error;
          });
      },
      async handleStripeSubmit() {
        const paymentData = {
          // Stripe requires underscore
          // eslint-disable-next-line camelcase
          billing_details: {
            email: this.currentUser.email,
          },
        };
        const createPaymentMethod = await this.stripe
          .createPaymentMethod('card', this.cardNumber, paymentData)
          .then((result) => {
            if (result.error) {
              this.errors = {
                card: result.error,
              };
              return false;
            }
            // Insert the token data.append(this.csrf.name, this.csrf.token);
            // into the form so it gets submitted to the server
            this.paymentMethodId = result.paymentMethod.id;
            // console.log('handle Stripe', result);
            return result.paymentMethod.id;
          });
        return createPaymentMethod;
      },
      async handleSubmit() {
        this.loading = true;

        let data;
        data = new FormData();
        data.append('firstName', this.cardFirstName);
        data.append('lastName', this.cardLastName);

        const stripeToken = await this.handleStripeSubmit(data);
        if (stripeToken !== false) {
          this.processPayment(stripeToken);
        } else {
          this.loading = false;
        }
      },
    },
  };
</script>
