<template>
  <div class="step-0 h-full">
    <div v-if="!user.firstName">
      <div class="mb-4">
        <p><b>A Bit About You</b></p>
      </div>
      <FormulateForm
        id="application-form"
        key="form"
        ref="signupForm"
        v-model="values"
        class="flex flex-wrap col-12"
        @submit="handleSubmitEmail"
      >
        <div class="row row--normal">
          <FormulateInput
            id="applicantFName"
            class="col-12"
            placeholder="First Name"
            name="firstName"
            validation-name="First Name"
            label-position="after"
            :class="{ 'opacity-50': success }"
            :disabled="success || loading"
            validation="required"
          />
          <FormulateInput
            id="applicantLName"
            class="col-12"
            placeholder="Last Name"
            name="lastName"
            validation-name="Last Name"
            label-position="after"
            :class="{ 'opacity-50': success }"
            :disabled="success || loading"
            validation="required"
          />

          <FormulateInput
            id="applicantEmail"
            class="col-12"
            placeholder="Email address"
            type="email"
            name="email"
            label-position="after"
            validation="required|email"
            :class="{ 'opacity-50': success }"
            :disabled="success || loading"
            validation-name="Email address"
          />
          <div class="mb-4 mt-4">
            <p><b>Create Password</b></p>
          </div>
          <FormulateInput
            id="applicantPassword"
            class="col-12"
            placeholder="Enter password"
            type="password"
            name="password"
            label-position="after"
            validation="required|min:6"
            :class="{ 'opacity-50': success }"
            :disabled="success || loading"
            validation-name="Password"
          />
          <FormulateInput
            id="applicantRePassword"
            class="col-12"
            placeholder="Re-enter Password"
            type="password"
            name="password_confirm"
            outer-class="input-wrap"
            label-position="after"
            validation="required|confirm|min:6"
            :class="{ 'opacity-50': success }"
            :disabled="success || loading"
            validation-name="Password confirmation"
          />

          <FormulateInput
            type="checkbox"
            :value="newsletter"
            name="newsletter"
            class="mt-4"
          >
            <template #label="{ label, id }">
              <label :for="id"> Subscribe to e-newsletter </label>
            </template>
          </FormulateInput>

          <div v-if="errors" class="col-12 mt-5">
            <ul>
              <li v-if="typeof errors === 'string'">
                <span>{{ errors }}</span>
              </li>
              <template v-else>
                <li v-for="(value, key) in errors">
                  <span class="is-capitalized">{{ key }} :</span>
                  <template v-if="typeof value === Array">
                    <span v-for="problem in value">{{ problem }} <br /></span>
                  </template>
                  <template v-else>
                    <span>{{ value }}</span>
                  </template>
                </li>
              </template>
            </ul>
          </div>

          <div class="col-12 mt-5">
            <button
              type="submit"
              form="application-form"
              class="button cursor-pointer"
              :class="[{ loading: loading }, { 'opacity-50': success }]"
              :disabled="loading || success"
            >
              Sign Up
            </button>
          </div>
        </div>
      </FormulateForm>
    </div>
    <div v-else>
      <p>
        <b>Hi {{ user.firstName }}</b>
      </p>
      <p>You are already a member and logged in</p>
      <div v-if="subscribed">
        <p>
          You also have an active Subscription.
          <br />
          <br />
          <a href="/account" class="button">Go to your account </a>
        </p>
      </div>
      <div v-else>
        <p>Continue to the purchase an issue or subscribe</p>
        <button
          class="button cursor-pointer"
          @click="skipToPayment"
          type="button"
        >
          Next Step
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { actions, getters } from '../../../vue/store.js';

  export default {
    name: 'StepZero',
    props: {
      user: {
        required: true,
      },
      subscribed: {
        type: Boolean,
        required: true,
      },
      content: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        newsletter: false,
        loading: false,
        step: 0,
        success: false,
        errors: '',
        values: {},
      };
    },
    computed: {
      csrf() {
        return getters.csrf();
      },
    },

    methods: {
      handleStep(step) {
        this.$emit('step', step);
      },
      skipToPayment() {
        this.handleStep(2);
      },
      async handleSubmitEmail() {
        this.loading = true;
        axios
          .get(
            `/actions/existinguser/existinguser/check-email?email=${this.values.email}`,
            {
              headers: {
                'Accept': 'application/json',
                'CRAFT-CSRF-TOKEN': this.csrf.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            return response.data;
          })
          .then((data) => {
            if (data.errors) {
              this.errors = data.errors;
            } else if (data.userExists) {
              this.errors = {
                email: `Sorry but the email ${this.values.email} is already in use.`,
              };
              this.loading = false;
            } else {
              this.errors = '';
              this.handleSubmit();
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error('Error:', error);
          });
      },
      async postMailchimp() {
        let formData = new FormData();
        formData.append('action', 'mailchimp/send');
        formData.append('audienceId', window.mcAudience);
        formData.append(this.csrf.name, this.csrf.token);
        formData.append('listId', window.mailchimpListId);
        formData.append('firstname', this.values.firstName);
        formData.append('lastname', this.values.lastName);
        formData.append('email', this.values.email);

        const response = await axios.post('/', formData).then((response) => {
          console.log(response);
          return response.data;
        });
        // console.log(response)
        return response;
      },
      handleSubmit() {
        const formData = new FormData();
        formData.append('action', 'users/save-user');
        formData.append(this.csrf.name, this.csrf.token);
        formData.append('firstName', this.values.firstName);
        formData.append('lastName', this.values.lastName);
        formData.append('email', this.values.email);
        formData.append('username', this.values.email);
        formData.append('password', this.values.password);

        axios
          .post('/', formData, {
            headers: { Accept: 'application/json' },
          })
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.data.errors) {
              this.errors = response.data.errors;
            }
            if (
              response.data.success ||
              response.data.message == 'User saved.'
            ) {
              if (this.newsletter) {
                postMailchimp().then(() => {
                  this.success = true;
                  this.handleStep(1);
                });
              } else {
                this.success = true;
                this.handleStep(1);
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
          });
      },
    },
  };
</script>
