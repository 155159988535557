<template>
<nav class="border-top border-dark w-100 pt-2 vue-filter d-sm-flex small-text">
	<b class="col-md-3 pb-3 d-block">
		Filter By:
	</b>
	<div class="buttons col-sm-3 pb-3">
		<b>Content:</b>
		<div
			v-for="(tag, i) in JSON.parse(JSON.stringify(tags))"
			:key="`resource-button-${i}`"
			class="has-hover cursor-pointer"
			:class="{ active: filters.tag === handelize(tag.handle) }"
			@click="updateFilterTag(tag)"
		>
			{{ tag.title }}
		</div>
	</div>
	<div class="buttons col-sm-6">
		<b>Age:</b>
		<div
			v-for="(tag, i) in JSON.parse(JSON.stringify(ages))"
			:key="`resource-button-${i}`"
			class="has-hover cursor-pointer"
			:class="{ active: filters.tag === handelize(tag.handle) }"
			@click="updateFilterTag(tag)"
		>
			{{ tag.title }}
		</div>
	</div>
</nav>
</template>

<script>
export default {
	name: 'ContentFilter',
	components: {},
	props: {
		tags: {
			type: Array,
			required: true,
		},
		ages: {  
			type: Array,
			required: true,
		},
	},
	emits: ['updated'],
	data() {
		return {
			windowWidth: '',
			loading: false,

			filters: {
				tag: '',
			},
		};
	},
	mounted() {
		this.filters.tag = JSON.parse(JSON.stringify(this.tags))[0].handle;
	},
	methods: {
		handelize(str) {
			return str
				.replace('& ', '')
				.replace(/\s/g, '-')
				.toLowerCase();
		},
		updateFilterTag(selected) {
			this.filters.tag = this.handelize(selected.handle);
			this.$emit('updated', this.handelize(selected.handle));
		},
	},
};
</script>
<style type="text/css">
.vue-filter {
	.active {
		opacity: .5;
	}
}
</style>

