<template>
  <div class="tamariki-form-wrap">
    <FormulateForm
      name="tamariki-form"
      id="tamariki-form"
      key="form"
      ref="tamarikiForm"
      class="col-12"
      @submit="handleSubmit"
    >
      <div class="row row--normal">
        <div class="col-12" v-for="(child, index) in childForms">
          <p>
            <b>Child {{ index + 1 }}</b>
          </p>
          <!-- These hidden fields are required when adding fields to a matrix block -->
          <input
            type="hidden"
            :name="`fields[userChildren][${index}][type]`"
            value="child"
          />
          <input
            type="hidden"
            :name="`fields[userChildren][${index}][enabled]`"
            value="1"
          />
          <FormulateInput
            type="text"
            :value="child.firstName"
            :name="`fields[userChildren][${index}][fields][firstName]`"
            placeholder="First name*"
            validation="required"
            validation-name="First name"
          />
          <FormulateInput
            type="text"
            :value="child.lastName"
            :name="`fields[userChildren][${index}][fields][lastName]`"
            placeholder="Last name (If different to yours)"
          />
          <div class="row row--normal">
            <div class="col-12 col-md-6">
              <FormulateInput
                type="text"
                :value="child.year"
                placeholder="Year of Birth"
                :name="`fields[userChildren][${index}][fields][year]`"
              />
            </div>
          </div>
          <FormulateInput
            type="text"
            :value="child.address"
            :name="`fields[userChildren][${index}][fields][address]`"
            placeholder="Postal address*"
            validation="required"
            validation-name="Postal address"
          />
          <div class="row row--normal">
            <div class="col-12 col-md-6">
              <FormulateInput
                type="text"
                :value="child.city"
                :name="`fields[userChildren][${index}][fields][city]`"
                placeholder="City*"
                validation="required"
                validation-name="City"
              />
            </div>
            <div class="col-12 col-md-6">
              <FormulateInput
                type="text"
                :value="child.postcode"
                :name="`fields[userChildren][${index}][fields][postcode]`"
                placeholder="Post code*"
                validation="required"
                validation-name="Post code"
              />
            </div>
          </div>

          <FormulateInput
            type="text"
            :value="child.country"
            :name="`fields[userChildren][${index}][fields][country]`"
            placeholder="Country*"
            validation="required"
            validation-name="Country"
          />

          <button
            @click="deleteRow(index)"
            class="mb-5 cursor-pointer"
            type="button"
            style="text-decoration: underline"
          >
            Remove
          </button>
        </div>
      </div>
      <div class="row row--normal">
        <FormulateInput
          v-if="signUp && childForms.length"
          type="checkbox"
          :value="privacy"
          name="privacyPolicy"
          validation="required"
          validation-name="Privacy Policy"
          class="mb-5"
        >
          <template #label="{ label, id }">
            <label :for="id">
              <span>
                I agree to Kiwi Kids Abroad’s
                <a href="/privacy-policy" class="link">privacy policy</a>.
              </span>
            </label>
          </template>
        </FormulateInput>

        <div class="tamarkiki-button-wrap">
          <button
            class="button cursor-pointer mb-4"
            @click="addRow"
            type="button"
          >
            + Add child
          </button>

          <button
            class="button cursor-pointer mb-4"
            :class="[{ loading: loading }]"
            :disabled="loading"
            type="submit"
          >
            {{ childForms.length < 2 ? 'Save Child' : 'Save Children' }}
          </button>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
  import axios from 'axios';
  import { actions, getters } from '../../../vue/store.js';

  export default {
    name: 'TamarikiForm',
    props: {
      userChildren: {
        type: Array,
        required: true,
      },
      signUp: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        privacy: false,
        success: false,
        loading: false,
        loaded: false,
        errors: [],
        childForms: [],
      };
    },

    mounted() {
      actions.csrfFetch();
      actions.currentUserFetch();
      if (this.userChildren.length) {
        this.userChildren.forEach((child) => {
          this.childForms.push(child);
        });
      }
    },
    computed: {
      csrf() {
        return getters.csrf();
      },
      currentUser() {
        return getters.currentUser();
      },
    },
    methods: {
      addRow() {
        this.childForms.push({
          childFirstName: '',
          childLastName: '',
          childYear: '',
          childAddress: '',
          childCity: '',
          childPostCode: '',
          childCountry: '',
        });
        this.$forceUpdate();
      },
      deleteRow(index) {
        this.childForms.splice(index, 1);
      },

      handleSubmit() {
        this.loading = true;
        const formData = new FormData(this.$refs.tamarikiForm.$el);
        console.log(this.$refs.tamarikiForm.$el);
        formData.append('action', 'users/save-user');
        formData.append('userId', this.currentUser.id);
        formData.append(this.csrf.name, this.csrf.token);
        console.log(formData);

        axios
          .post('/', formData, {
            headers: {
              Accept: 'application/json',
            },
          })
          .then((response) => {
            console.log(response.data);
            if (
              response.data.success ||
              response.data.message == 'User saved.'
            ) {
              this.success = true;
              this.$emit('success');
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
          });
      },
    },
  };
</script>

<style lang="scss">
  .tamarkiki-button-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
</style>
