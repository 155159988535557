<template>
  <div id="register-form" class="register-form fade-in" ref="wrap">
    <div class="row no-gap">
      <div class="col-lg-6 bg-green-1 text-black relative">
        <transition mode="out-in" name="group">
          <div
            v-if="currentStep == 0"
            class="wysiwyg section"
            v-html="marked(stepOne.sideText)"
          />
          <template v-else-if="currentStep == 1">
            <img
              v-if="secondStepImage"
              :src="secondStepImage.url"
              :alt="secondStepImage.tite"
              class="image--cover"
            />

            <div
              v-else
              class="wysiwyg section"
              v-html="marked(stepTwo.sideText)"
            />
          </template>
          <div
            v-else-if="currentStep == 2"
            class="wysiwyg section"
            v-html="marked(stepThree.sideText)"
          />
          <div
            v-else-if="currentStep == 3"
            class="wysiwyg section"
            v-html="marked(stepThree.successText)"
          />
        </transition>
      </div>
      <div class="col-lg-6 bg-green-3">
        <div class="section register-form__forms">
          <transition name="group" mode="out-in">
            <StepZero
              v-if="currentStep == 0"
              key="zero"
              :user="user"
              :content="stepOne"
              :subscribed="subscribed"
              @step="handleStep"
            />
            <StepOne
              v-else-if="currentStep == 1"
              key="one"
              :content="stepTwo"
              :user="user"
              :image="secondStepImage"
              :userChildren="userChildren"
              @step="handleStep"
            />
            <StepTwo
              v-else-if="currentStep == 2"
              key="two"
              :content="stepThree"
              :products="products"
              :planId="planId"
              :plan="JSON.parse(plan.planData)"
              :stripePk="stripePk"
              :user="user"
              :customer="customer"
              @step="handleStep"
            />
            <div v-else-if="currentStep == 3">
              <!-- <p><b>Success</b></p> -->
              <a href="/account" class="button cursor-pointer">
                Go to my account
              </a>
            </div>
          </transition>

          <ul class="step-indicator" :class="{ 'fade-out': currentStep > 2 }">
            <li
              v-for="step in 3"
              class="step-indicator__step"
              :class="{ active: step - 1 === currentStep }"
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { actions } from '../store.js';

import StepZero from '../../vue/modules/RegisterForm/RegisterStepZero.vue';
import StepOne from '../../vue/modules/RegisterForm/RegisterStepOne.vue';
import StepTwo from '../../vue/modules/RegisterForm/RegisterStepTwo.vue';

export default {
  name: 'RegisterForm',
  components: {
    StepOne,
    StepTwo,
    StepZero,
  },
  props: {
    cart: {
      type: Array,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },

    plan: {
      type: Object,
      required: true,
    },
    planId: {
      type: String,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
    stepOne: {
      type: Object,
      required: true,
    },
    stepTwo: {
      type: Object,
      required: true,
    },
    secondStepImage: {
      type: Object,
    },
    stepThree: {
      type: Object,
      required: true,
    },
    stripePk: {
      type: String,
      required: true,
    },
    subscribed: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    userChildren: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentStep: this.user && Object.keys(this.user).length != 0 ? 1 : 0,
    };
  },

  mounted() {
    actions.csrfFetch();
  },
  watch: {
    currentStep(newValue) {
      if (newValue === 3) {
        document.querySelector('.cover-text h1').innerHTML = 'Success!';
      }
    },
  },
  methods: {
    marked(value, options) {
      if (options) {
        return actions.marked(value, options);
      }
      return actions.marked(value);
    },
    handleStep(step) {
      this.currentStep = step;
      setTimeout(() => {
        this.$refs.wrap.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    },
  },
};
</script>
<style lang="scss">
.group-enter-active {
  opacity: 0;
  transition: opacity 0.4s ease 0.3s;
}
.group-leave-active {
  transition: opacity 0.3s ease;
  width: 100%;
}
.group-enter-to {
  opacity: 1;
}
.group-leave-to,
.group-enter {
  opacity: 0;
}
.register-form {
  .section {
    @media (max-width: 998px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}
.step-indicator {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .step-indicator__step {
    background: #fff;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-left: 10px;
    transition: all 0.3s ease;
    &:before {
      display: none;
    }
    &.active {
      background: #006938;
    }
  }
}

.register-form__forms {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.relative {
  position: relative;
}
.image--cover {
  object-fit: contain;
  width: 100%;
}
</style>
